<template>
  <generic-overview
    :route="route"
    :dialog="dialog"
    :filters="filters"
    :actions="actions"
    icon="business"
    title="Offers"
  >
    <div slot="content">
      <table-data-provider
        ref="formsTable"
        :url="url"
        :pagination="pagination"
        :filters="filters"
        :includes="includes"
        :entity-name="entityName"
        :schema="schema"
      >
        <v-data-table
          slot-scope="props"
          :headers="headers"
          :items="props.items"
          :pagination.sync="pagination"
          :total-items="props.totalItems"
          :rows-per-page-items="[20, 50, 100]"
          disable-initial-sort
          disable-page-reset
          :loading="props.isLoading"
          class="elevation-1"
        >
          <offers-overview-row
            slot="items"
            slot-scope="props"
            :offer="props.item"
          />
        </v-data-table>
      </table-data-provider>
    </div>
    <router-view slot="dialog" />
  </generic-overview>
</template>
<script type="text/babel">
import { forOwn } from "lodash";
import TableDataProvider from "@/components/legacy/generic/data/TableDataProvider";
import GenericOverview from "@/components/legacy/generic/overview/Overview";
import EntityOverviewMixin from "@/components/legacy/generic/entity/EntityOverviewMixin.vue";
import OffersOverviewRow from "./OffersOverviewRow.vue";
import { offer as schema } from "@/store/schema";

export default {
  name: "bo-man-companies-overview",
  components: {
    OffersOverviewRow,
    TableDataProvider,
    GenericOverview
  },
  mixins: [EntityOverviewMixin],
  data() {
    return {
      route: "distribution.offers",
      isFiltersOpen: false,
      isSidebarOpen: false,
      url: `api/v1/offers`,
      actions: [
        {
          icon: "add",
          color: "pink",
          to: { name: "management.companies.create" }
        }
      ],
      totalItems: 0,
      items: [],
      loading: true,
      pagination: {},
      reference: "companiesTable",
      headers: [
        { text: "Status", value: "status" },
        { text: "Reject request date", value: "rejection_requested_at" },
        { text: "Reject date", value: "rejected_at" },
        { text: "Accept date", value: "accepted_at" },
        {
          text: "Actions",
          align: "right",
          value: "name",
          sortable: false,
          width: "100px"
        }
      ],
      filters: {
        search: null,
        statuses: []
      },
      entityName: "offer"
    };
  },
  computed: {
    status() {
      return window.config.enums.offers.status;
    },
    selectStatus() {
      let statuses = [];
      forOwn(this.status, (property, value) =>
        statuses.push({ text: property, value: value })
      );
      return statuses;
    },
    schema() {
      return schema;
    }
  },
  methods: {
    goToDetail({ id }) {
      this.$router.push({
        name: "management.companies.dashboard",
        params: { id: id }
      });
    }
  }
};
</script>
