<template>
  <tr>
    <td>{{ offer.status }}</td>
    <td>{{ offer.rejection_requested_at }}</td>
    <td>{{ offer.rejected_at }}</td>
    <td>{{ offer.accepted_at }}</td>
    <td>
      <v-btn
        icon
        :to="{ name: 'distribution.offers.show', params: { id: offer.id } }"
      >
        <v-icon>far fa-search</v-icon>
      </v-btn>
    </td>
  </tr>
</template>
<script type="text/babel">
export default {
  name: "companies-overview-row",
  props: {
    offer: {
      type: Object,
      required: true
    }
  }
};
</script>
